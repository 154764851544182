import dayjs from "dayjs";

export const AmountSeparator = (amount, decimal) => {
  const separate = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const rounding = (Math.round(amount * 100) / 100).toFixed(
    decimal >= 0 ? decimal : 2
  );
  const cal = separate(rounding);
  return cal;
};

export const formatDate = (date, format) => dayjs(date).format(format); //format sample MMM DD, YYYY. h:mm a

export const US_STATES = [
  { state: "AL", name: "Alabama" },
  { state: "AK", name: "Alaska" },
  { state: "AS", name: "American Samoa" },
  { state: "AZ", name: "Arizona" },
  { state: "AR", name: "Arkansas" },
  { state: "CA", name: "California" },
  { state: "CO", name: "Colorado" },
  { state: "CT", name: "Connecticut" },
  { state: "DE", name: "Delaware" },
  { state: "DC", name: "District of Columbia" },
  { state: "FM", name: "Federated States of Micronesia" },
  { state: "FL", name: "Florida" },
  { state: "GA", name: "Georgia" },
  { state: "GU", name: "Guam" },
  { state: "HI", name: "Hawaii" },
  { state: "ID", name: "Idaho" },
  { state: "IL", name: "Illinois" },
  { state: "IN", name: "Indiana" },
  { state: "IA", name: "Iowa" },
  { state: "KS", name: "Kansas" },
  { state: "KY", name: "Kentucky" },
  { state: "LA", name: "Louisiana" },
  { state: "ME", name: "Maine" },
  { state: "MH", name: "Marshall Islands" },
  { state: "MD", name: "Maryland" },
  { state: "MA", name: "Massachusetts" },
  { state: "MI", name: "Michigan" },
  { state: "MN", name: "Minnesota" },
  { state: "MS", name: "Mississippi" },
  { state: "MO", name: "Missouri" },
  { state: "MT", name: "Montana" },
  { state: "NE", name: "Nebraska" },
  { state: "NV", name: "Nevada" },
  { state: "NH", name: "New Hampshire" },
  { state: "NJ", name: "New Jersey" },
  { state: "NM", name: "New Mexico" },
  { state: "NY", name: "New York" },
  { state: "NC", name: "North Carolina" },
  { state: "ND", name: "North Dakota" },
  { state: "MP", name: "Northern Mariana Islands" },
  { state: "OH", name: "Ohio" },
  { state: "OK", name: "Oklahoma" },
  { state: "OR", name: "Oregon" },
  { state: "PW", name: "Palau" },
  { state: "PA", name: "Pennsylvania" },
  { state: "PR", name: "Puerto Rico" },
  { state: "RI", name: "Rhode Island" },
  { state: "SC", name: "South Carolina" },
  { state: "SD", name: "South Dakota" },
  { state: "TN", name: "Tennessee" },
  { state: "TX", name: "Texas" },
  { state: "UT", name: "Utah" },
  { state: "VT", name: "Vermont" },
  { state: "VI", name: "Virgin Islands" },
  { state: "VA", name: "Virginia" },
  { state: "WA", name: "Washington" },
  { state: "WV", name: "West Virginia" },
  { state: "WI", name: "Wisconsin" },
  { state: "WY", name: "Wyoming" },
];
