import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from "./Components/Home";
import TaxRates from "./Components/TaxRates";
import Signin from "./Components/Signin";

function Routing({}) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/tax-rates" element={<TaxRates />}></Route>
        <Route exact path="/signin" element={<Signin />}></Route>
      </Routes>
    </Router>
  );
}
export default Routing;
