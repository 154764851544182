import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Routing from "./Routing";
import logo from "./logo.svg";
import { AppContextProvider } from "./hooks";

function App() {
  return (
    <AppContextProvider>
      <Routing />
      <ToastContainer />
    </AppContextProvider>
  );
}

export default App;
