import React from "react";

function MainWrapper({ children, style }) {
  return (
    <main className={`text-md font-inter sm:text-lg min-h-screen ${style} text-[#282C38]`}>
      {children}
    </main>
  );
}

export default MainWrapper;
