import React, { useEffect, useState } from "react";
import logo from "../assets/icons/logo.png";
import HomeIcon from "../assets/icons/home.svg";
import SettingsIcon from "../assets/icons/settings.svg";
import SignoutIcon from "../assets/icons/logout.svg";
import { useLocalStorage } from "../hooks";
import { Ping } from "../Api";

const options = [
  { id: "1", label: "Overview", href: "/", icon: HomeIcon },
  {
    id: "2",
    label: "Tax Rates",
    href: "/tax-rates",
    icon: SettingsIcon,
  },
];

function Sidebar({ active }) {
  const [activeTab, setActiveTab] = useState(active);
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [user, setUser] = useLocalStorage("user", {});

  const ping = async () => {
    const req = await Ping(authToken);
    if (req?.success) {
    } else {
      signOut();
    }
  };

  const signOut = () => {
    window.open("./signin", "_self");
    setAuthToken("");
    setUser({});
  };

  useEffect(() => {
    if (!authToken) {
      signOut();
    }
  }, [authToken]);

  useEffect(() => {
    ping();
  }, []);

  return (
    <div>
      <div className="hidden sm:block bg-green-100 xl:w-[20%] xl:min-w-[280px] py-6 fixed left-0 top-0 min-h-screen z-45">
        <div
          onClick={() => {
            window.open("/");
          }}
          className=" sm:flex pl-4 w-20 text-grey-900  justify-center items-center text-sm cursor-pointer"
        >
          <img src={logo} className="w-full hidden sm:block" alt="logo" />
        </div>

        <ul className="pl-3 whitespace-nowrap text-grey-700 text-sm font-medium flex flex-col mt-12 gap-2">
          {options.map((item) => (
            <div
              onClick={() => {
                setActiveTab(item);
                window.open(item.href, "_self");
              }}
              key={item.id}
              className={`${
                activeTab?.id == item.id ? "bg-white" : ""
              } flex items-center space-x-2 py-2 px-3 w-[96%] rounded-md text-xs sm:text-lg cursor-pointer`}
            >
              <img src={item.icon} className="w-4 min-w-4 h-4" alt="logo" />
              <div className="hidden md:block ">
                <span className="text-lg">{item.label}</span>
              </div>
            </div>
          ))}
        </ul>

        <div className="absolute bottom-0 w-full ">
          <div className="border-t-[1px] border-accent-dark mx-2 py-6 flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <div className="text-grey-900 text-sm ">
                {user?.avatar ? (
                  <img
                    src={user.avatar}
                    className="w-12 h-12 rounded-full object-cover"
                    alt="avatar"
                  />
                ) : (
                  <div className=" h-12 w-12 rounded-full bg-green-700 flex items-center justify-center font-semibold">
                    {`${user?.firstName?.substr(0, 1) || ""}${
                      user?.lastName?.substr(0, 1) || ""
                    }`}
                  </div>
                )}
              </div>
              <div>
                <h2 className="text-grey-700 font-medium text-sm">
                  {user?.firstName} {user?.lastName}
                </h2>
                <h3 className="text-grey-300 font-light text-sm">
                  {user?.email}
                </h3>
              </div>
            </div>

            <div
              onClick={() => {
                window.open("./signin", "_self");
                setAuthToken("");
                setUser({});
              }}
              className="cursor-pointer"
            >
              <img src={SignoutIcon} className="" alt="logout" />
            </div>
          </div>
        </div>
      </div>

      <div className="sm:hidden">
        <ul className="flex  items-center gap-4 px-2 py-4">
          {options.map((item) => (
            <div
              onClick={() => {
                setActiveTab(item);
                window.open(item.href, "_self");
              }}
              className={`cursor-pointer border rounded-full py-2 px-4 border-secondary ${
                item.id == activeTab.id ? "bg-green-500" : ""
              }`}
            >
              {item.label}
            </div>
          ))}
          <div
            onClick={() => {
              window.open("./signin", "_self");
            }}
            className={`cursor-pointer border rounded-full py-2 px-4 border-secondary`}
          >
            Sign out
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
