export const apiUrl = "https://intax.onrender.com";
//export const apiUrl = "http://localhost:4000";

export const SignIn = async (body) => {
  console.log(body);
  return await fetch(apiUrl + `/admin/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const SignUp = async (body) => {
  return await fetch(apiUrl + `/sign-up`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateTaxRate = async (body, authToken) => {
  return await fetch(apiUrl + `/admin/update-tax-rate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUser = async ({ authToken }) => {
  return await fetch(apiUrl + `/admin/fetch-user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const Ping = async (authToken) => {
  return await fetch(apiUrl + `/ping`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchTaxRates = async (authToken) => {
  return await fetch(apiUrl + `/admin/fetch-tax-rates`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUsers = async (authToken) => {
  return await fetch(apiUrl + `/admin/fetch-users`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
