import React, { useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import Sidebar from "./Sidebar";
import chart_up from "../assets/icons/chart-up.svg";
import chart_down from "../assets/icons/chart-down.svg";
import arrow_up from "../assets/icons/arrow-up.svg";
import arrow_down from "../assets/icons/arrow-down.svg";
import checkmark from "../assets/icons/checkmark.svg";
import { AmountSeparator, US_STATES, formatDate } from "../utilities";
import { FetchTaxRates, UpdateTaxRate } from "../Api";
import { useLoading, useLocalStorage } from "../hooks";
import { toast } from "react-toastify";
import Loader from "./Loader";

function TaxRates() {
  const [rate, setRate] = useState();
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [rates, setRates] = useState([]);

  const fetchRates = async () => {
    startLoading();
    const res = await FetchTaxRates(authToken);
    if (res?.success) setRates(res.rates);
    stopLoading();
  };

  useEffect(() => {
    fetchRates();
  }, []);

  async function handleSubmit() {
    if (!rate) return;
    console.log(rate);
    startLoading();
    const res = await UpdateTaxRate(rate, authToken);
    console.log(res);
    if (res?.success) {
      fetchRates();
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  }

  return (
    <MainWrapper>
      <Sidebar active={{ id: "2", label: "Tax Rates", href: "/tax-rates" }} />
      <Loader isLoading={isLoading} />
      <div className="sm:ml-[350px] min-h-screen">
        <div className="flex flex-col px-4 py-4">
          <span className="font-medium text-xl text-primary">Tax Rates</span>
        </div>
        {/* {Table} */}
        <div className="sm:py-20 py-12 sm:text-md flex justify-center flex-col px-8 gap-6">
          <table className=" rounded-lg">
            <thead>
              <tr className="bg-white border-[1px] font-medium rounded-lg whitespace-nowrap">
                <th className="text-left px-8 py-4">State</th>
                <th className="text-left px-8 py-4">Current Rate</th>
                <th className="text-left px-8 py-4">Set New Rate</th>
                <th className="text-left px-8 py-4 sm:hidden">Save</th>
              </tr>
            </thead>
            {rates.length > 0 ? (
              rates.map((item, index) => (
                <tbody key={item.state}>
                  <tr
                    className={`whitespace-nowrap border-[0.5px] ${
                      index % 2 == 0 ? "bg-[#F9FAFB]" : ""
                    }`}
                  >
                    <td className="px-8 py-4">
                      {item?.state
                        ? US_STATES.filter((e) => e.state == item.state)[0].name
                        : ""}
                      , {item.state}
                    </td>
                    <td className="px-8 py-4">{item.rate}</td>
                    <td className="px-8 py-4">
                      <input
                        maxLength={3}
                        className="bg-transparent focus:outline-secondary"
                        value={item.state == rate?.state ? rate.rate : ""}
                        onChange={(e) =>
                          setRate({ state: item.state, rate: e.target.value })
                        }
                      />
                    </td>
                    <td className="px-8 py-4">
                      <div onClick={handleSubmit}>
                        <img src={checkmark} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan={4}
                    className="text-center sm:text-lg mt-20 font-semibold opacity-50 font-public-sans py-12"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </MainWrapper>
  );
}

export default TaxRates;
