import React, { useState } from "react";
import MainWrapper from "./MainWrapper";
import Sidebar from "./Sidebar";
import chart_up from "../assets/icons/chart-up.svg";
import chart_down from "../assets/icons/chart-down.svg";
import arrow_up from "../assets/icons/arrow-up.svg";
import arrow_down from "../assets/icons/arrow-down.svg";
import { AmountSeparator, formatDate } from "../utilities";
import logo from "../assets/icons/logo.png";
import Loader from "./Loader";
import { useLoading, useLocalStorage } from "../hooks";
import { SignIn } from "../Api";
import { toast } from "react-toastify";

function Index() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [user, setUser] = useLocalStorage("user", {});
  const [_, setAuthToken] = useLocalStorage("authToken", "");

  const handleSignin = async () => {
    startLoading();
    if (!email || !password) {
      toast.error("Email and password required", { position: "top-center" });
      stopLoading();
      return;
    }
    const res = await SignIn({ email, password, remember: false });
    console.log(res);
    if (res?.success) {
      setUser(res.user);
      setAuthToken(res.authToken);
      window.open("./", "_self");
    } else {
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  return (
    <MainWrapper>
      <Loader isLoading={isLoading} />
      <div className="flex justify-center items-center min-h-screen">
        <div className="border flex flex-col rounded-lg gap-2 py-24 px-40">
          <img
            src={logo}
            className="hidden sm:block w-24 self-center"
            alt="logo"
          />
          <span className="text-center text-primary text-2xl font-semibold">
            Welcome back
          </span>
          <span className="text-center text-secondary">
            Please sign in to continue
          </span>

          <div className="flex flex-col mt-4">
            <span className="text-secondary">Email</span>
            <input
              className="border focus:outline-none rounded-sm py-1 border-secondary px-1"
              placeholder=""
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col mt-2">
            <span className="text-secondary">Password</span>
            <input
              className="border focus:outline-none rounded-sm py-1 px-1 border-secondary"
              placeholder=""
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div
            className="bg-green-400 cursor-pointer py-2 rounded-sm mt-4 text-primary flex justify-center items-center font-medium"
            onClick={handleSignin}
          >
            Signin
          </div>
        </div>
      </div>
    </MainWrapper>
  );
}

export default Index;
