import React, { useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import Sidebar from "./Sidebar";
import chart_up from "../assets/icons/chart-up.svg";
import chart_down from "../assets/icons/chart-down.svg";
import arrow_up from "../assets/icons/arrow-up.svg";
import arrow_down from "../assets/icons/arrow-down.svg";
import { AmountSeparator, US_STATES, formatDate } from "../utilities";
import { FetchUsers } from "../Api";
import { useLoading, useLocalStorage } from "../hooks";
import Loader from "./Loader";

function Home() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [user, setUser] = useLocalStorage("user", {});
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    startLoading();
    const res = await FetchUsers(authToken);
    console.log(res);
    if (res?.success) setUsers(res.users);
    stopLoading();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <MainWrapper>
      <Sidebar active={{ id: "1", label: "Overview", href: "/" }} />
      <Loader isLoading={isLoading} />
      <div className="sm:ml-[350px] min-h-screen">
        <div className="flex flex-col px-4 py-4">
          <span className="font-medium text-xl text-primary">
            Welcome back, {user.firstName} {user.lastName}
          </span>
          <span className="font-normal text-md text-secondary">
            Track, manage and tax of your customers.
          </span>
        </div>
        <div className="sm:px-8 px-4 mt-2">
          <div className="border w-72 px-3 py-6 rounded-lg flex flex-col gap-2">
            <span className="font-medium text-md text-primary">
              Total customers.
            </span>

            <div className="flex justify-between">
              <div className="flex flex-col self-end gap-3">
                <span className="font-semibold text-2xl text-primary">
                  {AmountSeparator(
                    users.filter((e) => e?.role == "INDIVIDUAL").length,
                    0
                  )}
                </span>
                <div className="flex gap-2 items-center">
                  <img src={arrow_up} className="" />
                  <span className="font-semibold text-md text-green-600">
                    10%
                  </span>
                  <span className="text-sm text-secondary">vs last month</span>
                </div>
              </div>
              <img src={chart_up} className="" />
            </div>
          </div>
        </div>

        {/* {Table} */}
        <div className="sm:py-20 py-12 sm:text-md flex justify-center flex-col sm:px-8 px-4 gap-6 overflow-x-scroll">
          <table className=" rounded-lg">
            <tbody>
              <tr className="bg-white border-[1px] font-medium rounded-lg whitespace-nowrap">
                <th className="text-left px-8 py-4">Users</th>
                <th className="text-left px-8 py-4">State</th>
                <th className="text-left px-8 py-4">Date Joined</th>
              </tr>
            </tbody>
            {users.length > 0 ? (
              users.map((item, index) => (
                <tbody key={item.email}>
                  <tr
                    className={`whitespace-nowrap border-[0.5px] ${
                      index % 2 == 0 ? "bg-[#F9FAFB]" : ""
                    }`}
                  >
                    <td className="px-8 py-4 flex gap-3">
                      <div className="text-grey-900 text-sm ">
                        {item?.avatar ? (
                          <img
                            src={item.avatar}
                            className="w-10 h-10 rounded-full object-cover"
                            alt="avatar"
                          />
                        ) : (
                          <div className=" h-10 w-10 rounded-full bg-green-700 flex items-center justify-center font-semibold uppercase">
                            {`${item?.firstName?.substr(0, 1) || ""}${
                              item?.lastName?.substr(0, 1) || ""
                            }`}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <span>
                          {item?.firstName || ""} {item?.lastName || ""}
                        </span>
                        <span className="text-secondary">{item.email}</span>
                      </div>
                    </td>
                    <td className="px-8 py-4">
                      {item?.state
                        ? US_STATES.filter((e) => e.state == item.state)[0].name
                        : ""}
                      , {item.state}
                    </td>
                    <td className="px-8 py-4">
                      {formatDate(item.createdAt, "MMM DD, YYYY")}
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan={4}
                    className="text-center sm:text-lg mt-20 font-semibold opacity-50 font-public-sans py-12"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </MainWrapper>
  );
}

export default Home;
